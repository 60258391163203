import orderItem from '../../components/order-item/index.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "current-orders",
  components: {
    orderItem
  },
  computed: {
    ...mapGetters({
      currentOrdersList:'profile/currentOrdersList',
      user: 'profile/user',
    })
  },
  created() {
    this.fetchCurrentOrders()
  },
  methods: {
    ...mapActions({
      fetchCurrentOrders:`profile/GET_CURRENT_ORDERS`
    })
  }
}
