import {VueSlideToggle} from "vue-slide-toggle";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {snakeCase} from 'lodash'
export default {
  name: "order-item",
  props: {
    order: {
      type: Object,
      default: () => {
      }
    },
    professional:{
      type:Boolean,
      default: () => false
    }
  },
  data() {
    return {
      open: false,
    }
  },
  components: {
    VueSlideToggle
  },
  computed:{
    ...mapGetters({
      repeatProducts:'order/repeatProducts',
      user: 'profile/user',
    })
  },
  methods:{
    ...mapActions({
      repeatOrder:`order/REPEAT_ORDER`
    }),
    ...mapMutations({
      resetBasket:`basket/RESET_BASKET`,
      addToBasket:`basket/ADD_ITEM_TO_BASKET`,
    }),
    repeat(){
      this.repeatOrder(this.order.id).then(()=>{
        this.resetBasket()
        for (let i in this.repeatProducts){
          if(this.repeatProducts[i])
          this.addToBasket({
            select_count:this.repeatProducts[i].selectCount,
            select_type:this.repeatProducts[i].selectType,
            ...this.repeatProducts[i]
          })
        }
        this.$router.push({name:'buy-flow-basket'}).catch(()=>{})
        console.log('repeat-complete',this.repeatProducts)
      })
    }
  }
}
